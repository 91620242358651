import { useGetConfigurationQuery } from "@gql-schema";
import H2 from "@ui/components/typography/H2";
import { resizerImageUrl } from "@uxf/core/utils/resizer";

interface Partner {
    title: string;
    url: string;
    imageUrl: string;
    type: "main-partner" | "partner";
}

export const partners: Partner[] = [
    {
        title: "McDonald's",
        url: "https://www.mcdonalds.cz/",
        imageUrl: "/images/loga/mcdonald_black.svg",
        type: "main-partner",
    },
    {
        title: "VETOX E s.r.o.",
        url: "https://www.tjdukla.cz/",
        imageUrl: "/images/loga/vetoxe_black.svg",
        type: "main-partner",
    },
    {
        title: "Liberecký kraj",
        url: "https://www.kraj-lbc.cz/",
        imageUrl: "/images/loga/lk_black.svg",
        type: "partner",
    },
    {
        title: "Město Liberec",
        url: "https://www.liberec.cz/cz/obcan/",
        imageUrl: "/images/loga/liberec_black.svg",
        type: "partner",
    },
    {
        title: "Svaz lyžařů ČR",
        url: "https://www.czech-ski.com/",
        imageUrl: "/images/loga/czechski_black.svg",
        type: "partner",
    },
    {
        title: "Národní sportovní agentura",
        url: "https://agenturasport.cz/",
        imageUrl: "/images/loga/nsa_black.svg",
        type: "partner",
    },
    {
        title: "Salomon",
        url: "https://www.salomon.com/",
        imageUrl: "/images/loga/salomon_black.svg",
        type: "partner",
    },
    {
        title: "Atomic",
        url: "https://www.atomic.com/en",
        imageUrl: "/images/loga/atomic_black.svg",
        type: "partner",
    },
    {
        title: "Suunto",
        url: "https://www.suunto.com/",
        imageUrl: "/images/loga/suunto_black.svg",
        type: "partner",
    },
    {
        title: "Nyč sport",
        url: "https://www.nyc-sport.cz/",
        imageUrl: "/images/loga/nyc_black.svg",
        type: "partner",
    },
    {
        title: "Haakon sport",
        url: "https://www.haakonsport.cz/",
        imageUrl: "/images/loga/haakon_black.svg",
        type: "partner",
    },
    {
        title: "Sporticus - specialista na outdoor",
        url: "https://www.sporticus.cz/",
        imageUrl: "/images/loga/sporticus_black.svg",
        type: "partner",
    },
    {
        title: "Bliz",
        url: "https://www.bliz.com/",
        imageUrl: "/images/loga/bliz_black.svg",
        type: "partner",
    },
    {
        title: "Toypex - hra a zdraví, to nás baví",
        url: "https://www.toypex.cz/",
        imageUrl: "/images/loga/toypex_black.svg",
        type: "partner",
    },
    {
        title: "FAJST - Transport to Norway",
        url: "ttps://www.fajst.cz/",
        imageUrl: "/images/loga/fajst_black.svg",
        type: "partner",
    },
    {
        title: "ANET Liberec - internet a televize",
        url: "https://www.anetliberec.cz/",
        imageUrl: "/images/loga/anet_black.svg",
        type: "partner",
    },
    {
        title: "Vojenské lesy a statky ČR, s.p.",
        url: "https://www.vls.cz/",
        imageUrl: "/images/loga/vls_black.svg",
        type: "partner",
    },
];

function Partners() {
    const { data: configData } = useGetConfigurationQuery();
    if (!configData) {
        return <div />;
    }

    const sponsors = configData.configuration.sponsors;

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                <H2 title="Partneři oddílu" />
                <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                    {sponsors
                        .filter((partner) => !partner.mainSponsor)
                        .map((partner) => {
                            /* eslint-disable react/jsx-no-target-blank */
                            return (
                                <a
                                    key={partner.url}
                                    href={partner.url}
                                    target="_blank"
                                    title={partner.name}
                                    className="col-span-1 flex justify-center bg-gray-50 p-8"
                                >
                                    <img
                                        className="h-12 max-h-12 w-60"
                                        src={resizerImageUrl(partner.logo ?? null, 240, 48, {}) ?? undefined}
                                        alt={partner.name}
                                    />
                                </a>
                            );
                            /* eslint-enable react/jsx-no-target-blank */
                        })}
                </div>
            </div>
        </div>
    );
}

export default Partners;
