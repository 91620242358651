interface Props {
    title: string;
    subtitle?: string;
}

function H1(props: Props) {
    return (
        <h1 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block">{props.title}</span>
            {props.subtitle && <span className="block text-indigo-900 xl:inline">{props.subtitle}</span>}
        </h1>
    );
}

export default H1;
