import { routeToUrl } from "@app-routes";
import { useGetContentsQuery } from "@gql-schema";
import { resizerImageUrl } from "@uxf/core/utils/resizer";
import { slugify } from "@uxf/core/utils/slugify";
import dayjs from "dayjs";
import Link from "next/link";
import { FC } from "react";

interface Props {
    limit: number;
    offset: number;
    tagCode: string;
}

const Articles: FC<Props> = (props) => {
    const { data } = useGetContentsQuery({
        variables: {
            limit: props.limit,
            offset: props.offset,
            tagCode: props.tagCode,
        },
    });

    if (!data) {
        return <div />;
    }

    const posts = data.contents;

    return (
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
                <Link
                    legacyBehavior
                    key={post.id}
                    href={routeToUrl("content/content", {
                        contentId: post.id,
                        slug: slugify(post.seo.name ?? post.name),
                    })}
                    passHref
                >
                    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                        <div className="shrink-0">
                            {post.image && (
                                <img
                                    className="h-48 w-full object-cover"
                                    src={resizerImageUrl(post.image, 512, 192, { position: "attention" }) ?? undefined}
                                    alt={post.name}
                                />
                            )}
                        </div>
                        <div className="flex flex-1 flex-col justify-between bg-white p-6">
                            <div className="mt-2 block flex-1">
                                <h3 className="min-h-[theme(spacing.12)] text-xl font-semibold text-gray-900">
                                    {post.name}
                                </h3>
                                <p className="mt-3 h-24 overflow-hidden text-base text-gray-700">{post.perex}</p>
                            </div>
                            <div className="mt-6 flex">
                                <div className="ml-3">
                                    <p className="text-xs text-gray-500">
                                        {post.author?.firstname} {post.author?.surname}
                                    </p>
                                    <div className="text-xs text-gray-500">
                                        {dayjs(post.publishedAt).format("D. M. YYYY")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default Articles;
