import {
    GetConfigurationDocument,
    GetConfigurationQuery,
    GetConfigurationQueryVariables,
    GetContentsDocument,
    GetContentsQuery,
    GetContentsQueryVariables,
    useGetConfigurationQuery,
} from "@gql-schema";
import Footer from "@main-zone/components/footer";
import Articles from "@main-zone/components/homepage/articles";
import Header from "@main-zone/components/homepage/header";
import MainPartners from "@main-zone/components/homepage/main-partners";
import Partners from "@main-zone/components/homepage/partners";
import HeadMetaTags from "@shared/components/head-meta-tags/head-meta-tags";
import { addApolloState, initializeApollo } from "@shared/lib/Apollo";
import { basicStaticProps } from "@shared/utils/basic-static-props";
import H1 from "@ui/components/typography/H1";
import H2 from "@ui/components/typography/H2";
import { GetStaticProps } from "next";
import { FC } from "react";

export const Page: FC = () => {
    const { data: configData } = useGetConfigurationQuery();
    if (!configData) {
        return <div />;
    }

    const configuration = configData.configuration;

    return (
        <>
            <HeadMetaTags
                title={`${configuration.h1Title} - ${configuration.h1Subtitle}`}
                description={configuration.descriptionMeta}
            />
            <Header>
                <H1 title={configuration.h1Title} subtitle={configuration.h1Subtitle} />
                <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                    <span className="text-neutral-500">{configuration.claim1}</span>{" "}
                    <span className="text-neutral-800">{configuration.claim2}</span>{" "}
                    <span className="text-neutral-500">{configuration.claim3}</span>{" "}
                    <span className="text-neutral-800">{configuration.claim4}</span>
                </p>
            </Header>
            <MainPartners />
            <div className="relative bg-gray-50 px-4 pb-20 pt-16 sm:px-6 lg:px-8 lg:pb-28 lg:pt-24">
                <div className="absolute inset-0">
                    <div className="h-1/3 bg-white sm:h-2/3" />
                </div>
                <div className="relative mx-auto max-w-7xl">
                    <H2 title="Aktuality" />
                    <Articles limit={15} offset={0} tagCode="HP" />
                </div>
            </div>
            <Partners />
            <Footer />
        </>
    );
};

export const getStaticProps: GetStaticProps = basicStaticProps(async () => {
    const apolloClient = initializeApollo(null);

    await apolloClient.query<GetConfigurationQuery, GetConfigurationQueryVariables>({
        query: GetConfigurationDocument,
        variables: {},
    });

    await apolloClient.query<GetContentsQuery, GetContentsQueryVariables>({
        query: GetContentsDocument,
        variables: { limit: 15, offset: 0, tagCode: "HP" },
    });

    return addApolloState(apolloClient, {
        props: {},
        revalidate: 10, // In seconds
    });
});
